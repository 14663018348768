import React, { Component } from 'react'
import Favicon from 'react-favicon';
import Responsive from 'react-responsive'
import ReactGA from 'react-ga'

import styles from './style.css'

// import discord from './discord-logo.png'
import discord from '../../assets/images/discord-logo-2.png'
import discordTwo from './discord-logo.png'
import logo from '../../assets/images/logo.png'
import appstore from '../../assets/images/app-store.png'
import googleplay from '../../assets/images/google-play.png'

const Desktop = props => <Responsive {...props} minWidth={768} />
const Tablet = props => <Responsive {...props} minWidth={768} maxWidth={991} />
const Mobile = props => <Responsive {...props} maxWidth={767} />

export default class App extends Component {
  componentDidMount() {
    ReactGA.initialize('UA-116289719-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
    document.body.addEventListener("touchmove", function(event) {
      event.preventDefault()
      event.stopPropagation()
    }, false)
  }

  goToDiscord() {
    ReactGA.event({
      category: 'Social',
      action: 'Visited Discord',
      label: 'https://discord.gg/rveanZG',
    })
    window.open("https://discord.gg/rveanZG")
  }

  openAppStore() {
    window.open("https://itunes.apple.com/us/app/alien-arsenal/id1337442705?mt=8")
  }

  openGooglePlay() {
    window.open("https://play.google.com/store/apps/details?id=com.eightcircuitstudios")
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.discordLink} onClick={this.goToDiscord}>
          <img
            src={discord}
            width="60px"
            draggable="false"
          />
        </div>
        <Favicon url="https://s3-us-west-1.amazonaws.com/aliens-web/images/favicon-32x32.png" />
        <Desktop>
          <div className={styles.desktopContainer}>
            <div className={styles.imageContainer}>
              <img src={logo} />
            </div>
          </div>
        </Desktop>
        <Mobile>
          <div className={styles.mobileContainer}>
            <div className={styles.imageContainer}>
              <img src={logo} />
            </div>
          </div>
        </Mobile>
      </div>
    )
  }
}
